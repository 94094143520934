import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import { Dropdown } from '../atoms';
import config from '../../config.json';
import { DropdownI, makeDropdownI } from '../../types/component/Dropdown';
import { PaymentCard } from '../atoms';
import euPlatescBanner from '../../assets/images/eu_platesc_banner.png';
import paypalBanner from '../../assets/images/paypal_banner.png';
import { getExchageRateList } from '../../services/external-service';
import { useDispatch } from 'react-redux';
import { appAddAlertAction, appSetLoaderAction } from '../../state/action';
import { AlertType, makeAlert } from '../../types/component/Alert';
import {
  EuPlatescResponseI,
  makeEuPlatescRequestI,
  makePaymentOptionI,
  PaymentOptionI,
  PaymentProvider,
} from '../../types/logic/Payment';
import { formatPrice } from '../../utils';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import { PaymentDetails } from '../../types/logic/Order';
import './Payment.scss';
import { requestEuPlatescPayment } from '../../services';
import { AxiosResponse } from 'axios';
import { Trans } from '@lingui/react';

type Props = {
  onNext: (e: PaymentDetails | null) => void;
  amountToPay: number | undefined;
  orderCode: string | undefined;
};

type CurrencySelectProp = {
  currency: string | null;
};

export const Payment: React.FunctionComponent<Props> = (props: Props) => {
  const { onNext, amountToPay, orderCode } = props;
  const dispatcher = useDispatch();
  const [paymentCurrencies, setPaymentCurrencies] = useState<Array<DropdownI>>(
    []
  );
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [paymentOptions, setPaymentOptions] = useState<Array<PaymentOptionI>>(
    []
  );

  useEffect(() => {
    setPaymentCurrencies([]);

    if (config && Array.isArray(config.PAYMENT_OPTIONS)) {
      const paymentOptions = config.PAYMENT_OPTIONS.map((option) => {
        return option.currency;
      });

      if (Array.isArray(paymentOptions)) {
        const uniqueOptions = paymentOptions.filter(
          (v, i, a) => a.indexOf(v) === i
        );

        const dropdownItems = uniqueOptions.map((option) =>
          makeDropdownI(option, option)
        );

        setPaymentCurrencies(dropdownItems);
      }
    }
  }, []);

  useEffect(() => {
    const paymentCurrencies = config.PAYMENT_OPTIONS;

    if (
      !Array.isArray(paymentCurrencies) ||
      !selectedCurrency ||
      !amountToPay ||
      amountToPay <= 0 ||
      !orderCode
    ) {
      return;
    }

    const currencyPayments = paymentCurrencies.filter(
      (obj) => obj.currency === selectedCurrency
    );

    if (!Array.isArray(currencyPayments)) {
      return;
    }

    if (
      currencyPayments.findIndex((currency) => currency.foreign === true) !== -1
    ) {
      dispatcher(appSetLoaderAction(true));
      getExchageRateList()
        .then((response) => {
          let exchangeRateList = null;
          if (
            response.data &&
            response.data.rates &&
            Array.isArray(response.data.rates)
          ) {
            exchangeRateList = response.data.rates;
          }

          if (
            Array.isArray(exchangeRateList) &&
            exchangeRateList.findIndex(
              (ex) => ex.currency === selectedCurrency
            ) !== -1
          ) {
            const exRate = exchangeRateList.find(
              (ex) => ex.currency === selectedCurrency
            );

            if (!exRate || !exRate.value) {
              return;
            }

            const payOptions: Array<PaymentOptionI> = [];
            currencyPayments.forEach((c) => {
              payOptions.push(
                makePaymentOptionI(
                  c.currency,
                  c.type,
                  c.fee,
                  c.foreign,
                  amountToPay,
                  exRate.value,
                  Math.trunc(
                    ((exRate.multiplier > 0
                      ? exRate.multiplier * amountToPay
                      : amountToPay) /
                      exRate.value) *
                      (c.fee && c.fee > 0 ? 1 + c.fee / 100 : 1)
                  )
                )
              );
            });
            setPaymentOptions(payOptions);
          } else {
            showCurrencyNotAvailableMessage(selectedCurrency);
          }
        })
        .catch(() => {
          showCurrencyNotAvailableMessage(selectedCurrency);
        })
        .finally(() => {
          dispatcher(appSetLoaderAction(false));
        });
    } else {
      const payOptions: Array<PaymentOptionI> = [];
      currencyPayments.forEach((c) => {
        payOptions.push(
          makePaymentOptionI(
            c.currency,
            c.type,
            c.fee,
            c.foreign,
            amountToPay,
            0,
            Math.trunc(amountToPay * (c.fee && c.fee > 0 ? 1 + c.fee / 100 : 1))
          )
        );
      });
      setPaymentOptions(payOptions);
    }
  }, [selectedCurrency, amountToPay]);

  const showCurrencyNotAvailableMessage = (
    currency: string | undefined | null
  ) => {
    if (!currency) {
      return;
    }

    dispatcher(
      appAddAlertAction(
        makeAlert(
          `Momentan nu putem accepta plati in ${currency}. Incercati alta valuta!`,
          AlertType.NOTE
        )
      )
    );
  };

  const getPaymentOption = (
    options: Array<PaymentOptionI> | undefined,
    type: string
  ) => {
    if (!Array.isArray(options)) {
      return null;
    }
    return options.find((op) => op.type === type);
  };

  const handleCurrencySelect = (e: DropdownI | null) => {
    if (!e) {
      return;
    }
    setSelectedCurrency(e.value.toString());
  };

  const CurrencySelect = (props: CurrencySelectProp) => {
    const { currency } = props;
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      if (!currency) {
        return;
      }
      dispatch({
        type: 'resetOptions',
        value: {
          ...options,
          currency,
        },
      });
    }, [currency]);

    return <></>;
  };

  //Paypal staff
  // eslint-disable-next-line
  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          invoice_id: orderCode,
          amount: {
            value: formatPrice(data.price),
            selectedCurrency,
          },
        },
      ],
    });
  };

  //Paypal staff
  // eslint-disable-next-line
  const handleAcceptOrder = (data: any, actions: any) => {
    // eslint-disable-next-line
    return actions.order.capture().then((details: any) => {
      console.log(details);
      if (
        details &&
        details.purchase_units &&
        Array.isArray(details.purchase_units) &&
        details.purchase_units[0] &&
        details.purchase_units[0].payments &&
        Array.isArray(details.purchase_units[0].payments.captures)
      ) {
        const transactionCaptures = details.purchase_units[0].payments.captures;
        if (Array.isArray(transactionCaptures)) {
          onNext({
            paymentProvider: PaymentProvider.PAYPAL,
            paymentTransactionId: details.id,
            orderCode: transactionCaptures[0].invoice_id,
          });
          return;
        }
      }

      onNext(null);
    });
  };

  const setEuPlatescPaymentModalVisible = () => {
    dispatcher(appSetLoaderAction(true));

    // TODO - update amont to real amount after EU Platesc switch from TEST acount
    // Test platform works wit 0.2
    const paymentRequestParam = makeEuPlatescRequestI(
      orderCode || '',
      0.2,
      selectedCurrency || 'RON',
      'Verbit TRAD'
    );
    requestEuPlatescPayment(paymentRequestParam)
      .then((response: AxiosResponse<EuPlatescResponseI>) => {
        if (response && response.data && response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      })
      .catch(() => {
        dispatcher(
          appAddAlertAction(
            makeAlert('Cerere plata esuata prin EuPlatesc', AlertType.ERROR)
          )
        );
      })
      .finally(() => {
        dispatcher(appSetLoaderAction(false));
      });
  };

  const setBankTransferModalAction = () => {
    dispatcher(appSetLoaderAction(true));
    onNext({
      paymentProvider: PaymentProvider.BANK_TRANSFER,
      orderCode: orderCode,
    });
  };

  return (
    <Form>
      <Row className="mt-3">
        <Col className="col-3 col-sm-3 col-md-3 align-self-center">
          <label>
            <Trans id="payment.currency" />
          </label>
        </Col>
        <Col className="col-9 col-sm-9 col-md-9 align-self-center">
          <FormGroup>
            <Dropdown
              options={paymentCurrencies}
              disabled={paymentCurrencies.length === 0}
              onSelect={handleCurrencySelect}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-6 col-sm-6 col-md-6 align-self-center">
          <PaymentCard
            disabled={
              !getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
            }
            id={1}
            color="white"
            imageSrc={euPlatescBanner}
            title={
              getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
                ? `${formatPrice(
                    getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
                      ?.price || 0,
                    2
                  )} ${
                    getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
                      ?.currency
                  }`
                : ''
            }
            description={
              getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC) &&
              getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
                ?.foreign
                ? `Curs valutar BNR (${
                    getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
                      ?.value
                  }) + ${
                    getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
                      ?.fee
                  }% folosit pentru exchange`
                : ''
            }
            onClick={() => {
              if (
                !getPaymentOption(paymentOptions, PaymentProvider.EU_PLATESC)
              ) {
                return;
              }
              setEuPlatescPaymentModalVisible();
            }}
          />
        </Col>
        <Col className="col-6 col-sm-6 col-md-6 align-self-center">
          <PaymentCard
            disabled={!getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)}
            id={2}
            color="white"
            imageSrc={paypalBanner}
            title={
              getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)
                ? `${formatPrice(
                    getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)
                      ?.price || 0,
                    2
                  )} ${
                    getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)
                      ?.currency
                  }`
                : ''
            }
            description={
              getPaymentOption(paymentOptions, PaymentProvider.PAYPAL) &&
              getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)?.foreign
                ? `Curs valutar BNR (${
                    getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)
                      ?.value
                  }) + ${
                    getPaymentOption(paymentOptions, PaymentProvider.PAYPAL)
                      ?.fee
                  }% folosit pentru exchange`
                : ''
            }
          >
            <PayPalScriptProvider
              options={{
                'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
              }}
            >
              <CurrencySelect currency={selectedCurrency} />
              {paymentOptions &&
                getPaymentOption(paymentOptions, PaymentProvider.PAYPAL) && (
                  <PayPalButtons
                    style={{ layout: 'horizontal' }}
                    createOrder={(data, action) =>
                      createOrder(
                        getPaymentOption(
                          paymentOptions,
                          PaymentProvider.PAYPAL
                        ),
                        action
                      )
                    }
                    onApprove={handleAcceptOrder}
                  />
                )}
            </PayPalScriptProvider>
          </PaymentCard>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-12 col-sm-12 col-md-12 align-self-center">
          <PaymentCard
            disabled={
              !getPaymentOption(paymentOptions, PaymentProvider.BANK_TRANSFER)
            }
            id={3}
            color="white"
            title="Bank Transfer"
            description="Detaliile pentru transfer primiti in emailul de confirmare comanda"
            size="small"
            onClick={() => {
              if (
                !getPaymentOption(paymentOptions, PaymentProvider.BANK_TRANSFER)
              ) {
                return;
              }
              setBankTransferModalAction();
            }}
          ></PaymentCard>
        </Col>
      </Row>
    </Form>
  );
};
